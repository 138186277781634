<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A compound with a molar mass of
        <number-value :value="molarMass" unit="\text{g/mol}" />
        has a heat of combustion of
        <number-value :value="heatofCombustion" unit="\text{kJ/mol.}" />
        In order to release
        <number-value :value="heatReleased" unit="\text{kJ}" />
        of heat, how much of this substance would need to be burned?
      </p>

      <calculation-input
        v-model="inputs.massSubstance"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question78',
  components: {
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massSubstance: null,
      },
    };
  },
  computed: {
    molarMass() {
      return this.taskState.getValueBySymbol('molarMass').content;
    },
    heatofCombustion() {
      return this.taskState.getValueBySymbol('heatofCombustion').content;
    },
    heatReleased() {
      return this.taskState.getValueBySymbol('heatReleased').content;
    },
  },
};
</script>
